import { createAsyncAction } from '../../utils/actionUtils';
import { HumidorStore } from '../../stores';
import { Transducer } from '../../utils/transducer';

export const LOAD_HUMIDORS = createAsyncAction('LOAD_HUMIDORS');
export const ADD_OR_UPDATE_HUMIDOR = 'ADD_OR_UPDATE_HUMIDOR';
export const ADD_OR_UPDATE_HUMIDOR_ENTRY = createAsyncAction('ADD_OR_UPDATE_HUMIDOR_ENTRY');
export const REMOVE_HUMIDOR_ENTRY = 'REMOVE_HUMIDOR_ENTRY';

/**
 * Gets all humidors for the specified user.
 * @param userId Boxpressd user ID of the user to get humidors for.
 * @returns {*} Array of humidors.
 */
export const getHumidors = (userId) => HumidorStore.getRawState().humidors[userId];

/**
 * Gets the active humidor for the logged in user.
 * @returns {void | {}} Active humidor object.
 */
export const getActiveHumidor = () => HumidorStore.getRawState().humidors.activeHumidor;

export const mergeHumidorEntry = (entry) => {
  console.log('Merging entry...');
  console.log(entry);
  HumidorStore.update((s) => {
    const humidors = s.humidors[entry.scan.userId || entry.scan.user.id] || [];
    console.log('Existing humidors:');
    console.log(JSON.stringify(humidors));
    const humidorId = entry.humidor_id;
    for (let i = 0; i < humidors.length; i++) {
      const humidor = humidors[i];
      if (humidor.id === humidorId) {
        console.log('Found the correct humidor for the entry!');
        console.log(JSON.stringify(humidor));
        const { contents } = humidor;
        let found = false;
        for (let j = 0; j < contents.length; j++) {
          if (contents[j].id === entry.id) {
            found = true;
            if (entry.qty === 0) {
              // Remove the entry
              humidor.contents.splice(j, 1);
            } else {
              console.log('Found the entry! Replacing it now...');
              // Replace and finish
              contents[j] = entry;
            }
            Transducer.setPreference('activeHumidor', humidor);
          }
        }
        if (!found) {
          console.log('Could not find the entry, adding it now...');
          // We reached the end and couldn't find it, add to the top of the list
          humidor.contents.unshift(entry);
        }
        // Updates the cached humidor
        Transducer.setPreference('activeHumidor', humidor);
      }
    }
    // s.humidors = humidors;
  });
};

export const deleteHumidorEntry = (entry) => {
  mergeHumidorEntry({
    ...entry,
    qty: 0,
  });
};

export function requestHumidors(userId, callback) {
  console.log('Requesting humidors...');
  return {
    type: LOAD_HUMIDORS.REQUEST,
    userId,
    callback,
  };
}

export function loadHumidors(userId) {
  return {
    type: LOAD_HUMIDORS.FETCH,
    userId,
  };
}

export function loadHumidorsSuccess(userId, humidors, callback) {
  HumidorStore.update((s) => {
    s.humidors[userId] = humidors;
  });
  return {
    type: LOAD_HUMIDORS.SUCCESS,
    userId,
    humidors,
    callback,
  };
}

export function loadHumidorsFail(userId, error) {
  return {
    type: LOAD_HUMIDORS.FAILURE,
    userId,
    error,
  };
}

// TODO Include user ID?
export function addOrUpdateHumidor(humidor) {
  return {
    type: ADD_OR_UPDATE_HUMIDOR,
    humidor,
  };
}

export function addOrUpdateHumidorEntry(entry, callback) {
  console.log('addOrUpdateHumidor');
  console.log(entry);
  return {
    type: ADD_OR_UPDATE_HUMIDOR_ENTRY.REQUEST,
    userId: entry.scan.user_id,
    humidorId: entry.humidor_id,
    entry,
    callback,
  };
}

// FIXME We don't really need this - it's only used to show loading indicators
export function addOrUpdateHumidorEntryFetching(entry) {
  return {
    type: ADD_OR_UPDATE_HUMIDOR_ENTRY.FETCH,
    entry,
  };
}

export function addUpdateHumidorEntrySuccess(entry, callback) {
  mergeHumidorEntry(entry);
  return {
    type: ADD_OR_UPDATE_HUMIDOR_ENTRY.SUCCESS,
    userId: entry.scan.user_id,
    humidorId: entry.humidor_id,
    entry,
    callback,
  };
}

export function addUpdateHumidorEntryFail(entry, error) {
  return {
    type: ADD_OR_UPDATE_HUMIDOR_ENTRY.FAILURE,
    entry,
    error,
  };
}

export function removeHumidorEntry(entry) {
  deleteHumidorEntry(entry);
  return {
    type: REMOVE_HUMIDOR_ENTRY,
    userId: entry.scan.user.id,
    humidorId: entry.humidor_id,
    entry,
  };
}
