import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import React, { useState } from 'react';
import { isMobile } from 'mobile-device-detect';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button as MaterialButton } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ModalView from '../../components/ModalView';
import Icon from '../../components/Icon';
import HumidorEditor from '../HumidorEditor';
import Placeholder from '../../../config/placeholder.config';

function HumidorManager(props) {
  const [showHumidorEditor, setShowHumidorEditor] = useState(false);
  const [selectedHumidor, setSelectedHumidor] = useState(null);

  const renderContents = () => (
    <List>
      {!isMobile && (!props.humidors || props.humidors.length === 0) && (
        <div style={{ textAlign: 'center' }}>
          <img src="https://cdn.boxpressd.io/placeholder/no_humidor.png" style={{ maxWidth: 200, marginBottom: 20, marginTop: 30, opacity: 0.5 }} />
          <div style={{ fontSize: 14 }}>No Virtual Humidors</div>
        </div>
      )}
      { props.humidors && props.humidors.map((humidor) => (
        <ListItem
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedHumidor(humidor);
            setShowHumidorEditor(true);
          }}
        >
          <ListItemAvatar>
            <Avatar alt={humidor.name} src={humidor.image_url || Placeholder.covers.humidor} />
          </ListItemAvatar>
          <ListItemText
            primary={humidor.name}
            // secondary={`Created ${renderTimestamp(humidor.timestamp)}`}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => {
                setSelectedHumidor(humidor);
                setShowHumidorEditor(true);
              }}
            >
              <Icon name="edit" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  const renderHumidorEditor = () => (
    <HumidorEditor
      show={showHumidorEditor}
      humidors={props.humidors}
      humidor={selectedHumidor}
      onClose={() => {
        setShowHumidorEditor(false);
      }}
    />
  );

  if (isMobile) {
    return (
      <ModalView
        title="Manage Humidors" // FIXME Switch based on adding or editing
        open={props.open}
        showFrom="right"
        // onClick={props.toggleOpen}
        onClose={props.onClose}
        rightButtons={[
          <MaterialButton
            aria-label="Add Humidor"
            onClick={() => {
              setSelectedHumidor(null);
              setShowHumidorEditor(true);
            }}
          >
            <Icon name="plus" style={{ height: 20, width: 20 }} />
          </MaterialButton>,
        ]}
      >
        <div style={{ padding: 20 }}>
          {renderContents()}
        </div>
        {showHumidorEditor && renderHumidorEditor()}
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {renderContents()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>Close</Button>
        <Button
          color="primary"
          onClick={() => {
            setSelectedHumidor(null);
            setShowHumidorEditor(true);
          }}
        >
          {'Add New Humidor'}
        </Button>
      </ModalFooter>
      {renderHumidorEditor()}
    </Modal>
  );
}

export default HumidorManager;
